.card {
  box-shadow: none !important;
}
.card-body .text-muted {
  color: #bcbcbc !important;
  font-size: 0.8rem;
  font-weight: 300;
}

.card-body span.h2 {
  margin-right: 10px;
}

.nav.nav-tabs .nav-item {
  font-weight: 300 !important;
}
.relative {
  position: relative;
}
.avatar-wrapper {
  border-radius: 50%;
  border: 1px solid #dfe0eb;
  padding: 2px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar {
  width: 87.37px !important;
  height: 87.37px !important;
  /* object-fit: cover; */
  border-radius: 50%;
  /* padding: 8.2px; */
}
label {
  color: #0f1f26;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
p.value {
  color: #bcbcbc;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.small {
  font-size: 16px !important;
}
.custom-btn {
  display: flex;
  align-items: center;
  border: 1px solid #2bc38c;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5em 1em;
  max-width: 200px;
}
input.password-error {
  border: 1px solid red;
  outline-color: red;
}
span.password-error {
  font-size: x-small;
  color: red;
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}
